<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="page-internal-server-error container">
  <h1>500</h1>
  <h1>{{"500.page-service-unavailable" | translate}}</h1>
  <p>{{"500.description" | translate}}</p>
  <p>{{"500.apology" | translate}}</p>
  <p>{{"500.suggestions-title" | translate}}</p>
  <ul>
    <li>{{"500.suggestion.refresh" | translate}}</li>
    <li>{{"500.suggestion.different-browser" | translate}}</li>
    <li>{{"500.suggestion.clear-cache" | translate}}</li>
    <li>{{"500.suggestion.try-later" | translate}}</li>
  </ul>
  <p>{{"500.contact-help" | translate}} <a href="mailto:digirep@iastate.edu">digirep@iastate.edu</a></p>
  <br/>
  <p class="text-center">
    <a href="/home" class="btn btn-primary">{{"500.link.home-page" | translate}}</a>
  </p>
</div>

