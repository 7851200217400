/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {ChangeDetectionStrategy, Component} from '@angular/core';
import { PageInternalServerErrorComponent as BaseComponent } from '../../../../app/page-internal-server-error/page-internal-server-error.component';

/**
 * This component representing the `PageInternalServer` DSpace page.
 */
@Component({
  selector: 'ds-page-internal-server-error',
  styleUrls: ['./page-internal-server-error.component.scss'],
  templateUrl: './page-internal-server-error.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class PageInternalServerErrorComponent extends BaseComponent {}
