import { combineLatest as observableCombineLatest } from 'rxjs';
import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { hasValue } from '../../shared/empty.util';
import {
  BrowseByMetadataPageComponent,
  browseParamsToOptions
} from '../browse-by-metadata-page/browse-by-metadata-page.component';
import { BrowseEntrySearchOptions } from '../../core/browse/browse-entry-search-options.model';
import { DSpaceObjectDataService } from '../../core/data/dspace-object-data.service';
import { BrowseService } from '../../core/browse/browse.service';
import { SortDirection, SortOptions } from '../../core/cache/models/sort-options.model';
import { BrowseByDataType, rendersBrowseBy } from '../browse-by-switcher/browse-by-decorator';
import { PaginationService } from '../../core/pagination/pagination.service';
import { map, switchMap } from 'rxjs/operators';
import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';

@Component({
  selector: 'ds-browse-by-title-page',
  styleUrls: ['../browse-by-metadata-page/browse-by-metadata-page.component.scss'],
  templateUrl: '../browse-by-metadata-page/browse-by-metadata-page.component.html'
})
/**
 * Component for browsing items by title (dc.title)
 */
@rendersBrowseBy(BrowseByDataType.Title)
export class BrowseByTitlePageComponent extends BrowseByMetadataPageComponent {

  public constructor(protected route: ActivatedRoute,
                     protected browseService: BrowseService,
                     protected dsoService: DSpaceObjectDataService,
                     protected paginationService: PaginationService,
                     protected router: Router,
                     protected authorizationDataService: AuthorizationDataService) {
    super(route, browseService, dsoService, paginationService, router, authorizationDataService);
  }

  ngOnInit(): void {
    this.browseId = this.route.snapshot.params.id;
    this.subs.push(
      this.browseService.getConfiguredSortDirection(this.browseId, SortDirection.ASC).pipe(
        map((sortDir) => new SortOptions(this.browseId, sortDir)),
        switchMap((sortConfig) => {
          this.currentSort$ = this.paginationService.getCurrentSort(this.paginationConfig.id, sortConfig, false);
          this.currentPagination$ = this.paginationService.getCurrentPagination(this.paginationConfig.id, this.paginationConfig);
          return observableCombineLatest([this.route.params, this.route.queryParams, this.scope$, this.currentPagination$, this.currentSort$]).pipe(
            map(([routeParams, queryParams, scope, currentPage, currentSort]) => ({
              params: Object.assign({}, routeParams, queryParams), scope, currentPage, currentSort,
            })),
          );
        })).subscribe(({ params, scope, currentPage, currentSort }) => {
        this.updatePageWithItems(browseParamsToOptions(params, scope, currentPage, currentSort, this.browseId), undefined, undefined);
        this.updateParent(params.scope);
        this.updateStartsWithTextOptions();
      }));
  }

  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
